/* custom styles class */
@layer components {
  .text-hover-transform-x {
    @apply relative after:absolute after:w-0 after:right-0 after:top-7 after:h-0.5 after:bg-bbc-primary after:transition-all after:duration-200 after:ease-linear hover:after:w-full hover:after:left-0 hover:after:right-0;
  }
  // checkbox
  .bbc-checkbox {
    @apply align-middle cursor-pointer  border-[#BDBDBD]  rounded-[4px]
            focus:bg-white focus:ring-offset-0 focus:ring-0
            transition-colors duration-200;
  }
  // checkbox radio
  .bbc-radio {
    @apply align-middle cursor-pointer border-[#BDBDBD] text-transparent
            focus:shadow-none focus:ring-0 focus:ring-transparent focus:border-bbc-brand-500
            checked:bg-[url('/assets/icons/radio.svg')] checked:bg-no-repeat checked:bg-auto checked:border-bbc-brand-500 checked:border-2
            checked:focus:border-bbc-brand-500 checked:hover:border-bbc-brand-500
            transition duration-200;
  }
  // dropdown(list box)
  .dropdown-default {
    @apply inline-block w-fit bg-white shadow-bbc rounded-xl relative min-w-[300px] text-left py-3;
  }
  //banner title
  .banner-default-title {
    @apply text-4xl leading-[50px] font-semibold xl:text-5xl xl:leading-[68px] text-center lg:text-left;
  }
  .banner-title-default-color {
    @apply bg-bbc-gradient-liner text-transparent bg-clip-text;
  }
  .banner-title-gradient-color {
    @apply bg-bbc-gradient-liner1 text-transparent bg-clip-text;
  }
  // title
  .bbc-title {
    @apply font-semibold text-3xl leading-[44px] lg:text-bbc-40px lg:leading-[56px] text-bbc-brand-800;
  }
  .bbc-title-30 {
    @apply font-semibold text-2xl leading-[36px] lg:text-3xl lg:leading-[44px] text-bbc-brand-700 text-center lg:text-left mb-4 lg:mb-6;
  }
  // bbc-accordion
  @mixin spacing-base($mb) {
    p,
    div {
      @apply mb-4;
      &:last-child {
        margin-bottom: $mb;
      }
    }
    ol {
      &:last-child {
        margin-bottom: $mb;
      }
      @apply mb-4 pl-5;

      li {
        @apply list-decimal;
        ul {
          li {
            @apply list-decimal;
          }
        }
      }
    }
    ul {
      &:last-child {
        margin-bottom: $mb;
      }
      @apply mb-4 pl-5;

      li {
        &::marker {
          font-size: 12px;
        }
        @apply list-disc;

        ul {
          li {
            &::marker {
              font-size: 12px;
            }
            @apply list-circle;
          }
        }
      }
    }
    a {
      @apply text-bbc-brand-500 hover:to-bbc-brand-700;
    }
  }
  // bbc-accordion
  .bbc-accordion {
    .bbc-accordion-panel {
      @include spacing-base(24px);
    }
  }
  .step-slider,
  .card-bank,
  .paragraph {
    @include spacing-base(0px);
  }
  .blue-marker {
    ul {
      li {
        &::marker {
          font-size: 14px;
          @apply text-bbc-secondary-100;
        }
        @apply list-disc;
      }
    }
  }
  // invalid text field
  .invalid-textfield {
    @apply focus:outline-hidden focus:bg-white focus:ring-0
                                          invalid:border-pink-500 invalid:text-pink-600
                                          focus:invalid:border-pink-500 focus:invalid:ring-pink-500
                                          transition-colors duration-200;
  }
  //bullet
  .bbc-pagination-bullet {
    @apply transition-all duration-300 ease-in-out relative inline-block cursor-pointer mx-1 w-2.5 h-2.5 rounded-full border border-bbc-brand-700 bg-transparent opacity-100;
  }
  .bbc-pagination-bullet-active {
    @apply w-10 bg-bbc-brand-700 rounded-md;
  }

  .title-h2 {
    @apply text-3xl leading-[44px] lg:text-bbc-40px font-semibold capitalize;
  }
  .bbc-pagination-circle-bullet-active {
    @apply bg-bbc-brand-700 opacity-100;
  }
  // steps slider

  .pagination-bullet {
    @apply bbc-pagination-bullet;
  }

  .pagination-bullet.pagination-bullet-active {
    @apply bbc-pagination-bullet-active
   lg:w-auto lg:bg-inherit
   after:lg:w-6 after:lg:h-6 after:lg:rounded-full after:lg:bg-bbc-brand-600;
  }

  .pagination-bullet {
    @apply after:content-none before:content-none
   after:lg:absolute  after:lg:content-[''] after:lg:left-1/2 after:lg:top-1/2 after:lg:-translate-x-1/2 after:lg:-translate-y-1/2 after:lg:w-3 after:lg:h-3 after:lg:rounded-full after:lg:duration-500 after:lg:ease-in-out after:lg:bg-bbc-brand-200
   lg:relative lg:w-auto lg:flex-1 lg:transition-transform lg:flex-grow lg:border-0
   before:lg:content-[''] before:lg:border-r before:lg:border-bbc-brand-200 before:lg:w-[1px] before:lg:absolute before:lg:h-full before:lg:last:h-1/2 before:lg:last:top-0 before:lg:first:h-1/2 before:lg:bottom-0;
  }
  .page-content {
    ol {
      @apply list-decimal;
    }
    ul {
      @apply list-disc;
    }
    ol,
    ul {
      li {
        @apply mt-2;
      }
    }
    table {
      @apply shadow-[0px_9px_24px_rgba(196,196,196,0.25)] rounded-[5px] bg-bbc-package mt-10 mb-6 mx-auto max-w-[540px] w-full text-center;
      thead {
        tr {
          @apply bg-bbc-gradient-liner;
        }
      }
      tbody {
        tr {
          @apply border-b border-b-[#797979] last:border-b-0;
          &:last-child {
            td {
              @apply first:rounded-bl-[5px] last:rounded-br-[5px];
            }
          }
        }
      }
      th {
        @apply lg:text-lg p-4 lg:p-6 w-1/2 text-white font-medium lg:font-semibold first:rounded-tl-[5px] last:rounded-tr-[5px];
      }
      td {
        @apply px-4 py-3 lg:p-4 text-sm leading-6 lg:text-lg font-medium;
      }
    }
    a {
      @apply text-bbc-brand-500;
    }
  }
  #progress {
    @apply fixed z-40 bottom-1/4 grid right-6 h-14 w-14 place-items-center rounded-full shadow-bbc cursor-pointer transition-all duration-300 opacity-0;
  }
  #progress-value {
    @apply grid h-[calc(100%-8px)] w-[calc(100%-8px)]  bg-white rounded-full place-items-center text-4xl text-bbc-brand-700;
  }
  .shadow-bbc {
    box-shadow: 0px 12px 32px rgb(181 181 181 / 20%);
  }
}
// image banner
.bbc-banner-image-country {
  @apply relative lg:absolute lg:bottom-0 xl:bottom-auto lg:top-0 xl:top-32 lg:mt-14 xl:mt-0
   flex justify-center lg:justify-start items-center 
   w-full m-auto  lg:max-w-[400px]
   max-w-[500px]  xl:max-w-[560px];
  @media (min-width: 1536px) {
    & {
      max-width: initial !important;
    }
  }
}
.bbc-banner-image-bottom-0 {
  @apply relative lg:absolute bottom-0  
   flex justify-center lg:justify-start items-center 
   w-full lg:pb-0 m-auto  lg:max-w-[400px]  
   max-w-[500px]  xl:max-w-[560px];
   @media (min-width: 1536px) {
    & {
      max-width: initial !important;
    }
  }
}
//react-lite-youtube-embed
.yt-lite.yt {
  &:before {
    content: none;
  }
}

// bbc-banner-image pb-[98px] max-w-[500px]  xl:bottom-[70px]  lg:max-w-[400px]  xl:max-w-[560px]
