section {
  margin-bottom: 80px;
  @media (max-width: theme("screens.lg")) {
    margin-bottom: 40px;
  }
}
.shadowMenu {
  box-shadow: 0px 4px 16px rgba(37, 37, 37, 0.25);
}
.shadow-bbc {
  box-shadow: 0px 12px 32px rgb(181 181 181 / 20%);
}
.shadow-bbc-table {
  box-shadow: 0px 9px 24px rgba(196, 196, 196, 0.25);
}
.shadow-b-bbc {
  box-shadow: 0px 20px 20px 0px rgb(181 181 181 / 20%);
}
.shadow-table {
  box-shadow: 0px 9px 24px rgba(196, 196, 196, 0.25);
}
.bg-wmud {
  background: linear-gradient(165.49deg, rgba(117, 121, 231, 0.7) -116.25%, rgba(185, 255, 252, 0.7) 98.96%);
}
