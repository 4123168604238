@tailwind base;
@tailwind components;
@tailwind utilities;



// Custom Styles
@import "custom/_styles";

// Custom Variables
@import "custom/_variables";

@font-face {
    font-family: "Poppins";
    font-display: swap;
    src: url(/assets/fonts/regular.woff2) format("woff2");
}
@font-face {
    font-family: "Poppins";
    font-weight: 500;
    font-display: swap;
    src: url(/assets/fonts/500.woff2) format("woff2");
}
@font-face {
    font-family: "Poppins";
    font-weight: 600;
    font-display: swap;
    src: url(/assets/fonts/600.woff2) format("woff2");
}
@font-face {
    font-family: "Poppins";
    font-weight: 700;
    font-display: swap;
    src: url(/assets/fonts/700.woff2) format("woff2");
}

html {
    -webkit-tap-highlight-color: transparent;
}
